@import '../../../src/Wex/BaseBundle/Resources/css/partials/palette';

#main {
  position: fixed;
  z-index: 1000;
}

#home-box {
  max-width: 10rem;

  #logo {
    img {
      max-width: 100%;
    }
  }
}

body #content-black > a#login-link {
  background: white;
  display: block;
  padding: 0.8rem 1.5rem 0.6rem;
  border-radius: 10rem;
  color: $dark-gray;
  margin: 1rem;

  .icon {
    color: $medium-gray;
    vertical-align: top;
    margin: -0.2rem 0.3rem 0.2rem -0.4rem;
  }
}
